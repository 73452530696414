@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --color-primary: #e06f2c;
  --color-secondary: #12141e;
  --color-tertiary: #191b25;
  --color-background: #ffffff;
  --color-backgroundGray: #f1f1f1;
  --color-grayLight: #eaeaea;
  --color-input-bg: #f2f2f2;

  --color-text: #000;

  --gradient-heading: linear-gradient(
    265.63deg,
    #f8a460 8.66%,
    #e1702d 100.52%
  );
  --gradient-button: linear-gradient(99.78deg, #e06f2c 24.73%, #ffb570 109.55%);
  --gradient-most-popular: linear-gradient(90deg, #e1702d 0%, #f8a45f 100%);

  --color-disabled: #a0aec0;
}

body {
  background-color: var(--color-background);
  color: var(--color-gray);
  line-height: 1.5;
}

@layer base {
  h1 {
    font-size: 38px;
    line-height: 55px;
    font-weight: 400;
    text-align: left;
    background: var(--gradient-heading);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: "Radio Canada Big", sans-serif;

    @screen sm {
      font-size: 55px !important;
      line-height: 74px !important;
    }

    @screen xl {
      font-size: 81px !important;
      line-height: 112px !important;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 35px;
    font-weight: 500;
    text-align: center;
    background: var(--gradient-heading);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: "Radio Canada Big", sans-serif;

    @screen sm {
      font-size: 27px;
      line-height: 40px;
    }

    @screen lg {
      font-size: 35px;
      line-height: 55px;
      letter-spacing: 0.02em;
    }
  }

  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: var(--color-secondary);
    @screen sm {
      font-size: 16px;
      line-height: 24px;
    }

    @screen lg {
      font-size: 19px;
      line-height: 28.5px;
    }
  }

  h4 {
    font-size: 30px;
    line-height: 52px;
    font-weight: 500;
    text-align: center;

    @screen sm {
      font-size: 37px;
      line-height: 72px;
    }

    @screen lg {
      font-size: 45px;
      line-height: 112px;
    }
  }

  h5 {
    font-size: 15px;
    line-height: 22.5px;
    font-weight: 400;
    text-align: center;
    color: #6f6f6f;

    @screen sm {
      font-size: 19px;
      line-height: 28px;
    }

    @screen lg {
      font-size: 23px;
      line-height: 34.5px;
    }
  }

  h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}

/* Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--color-tertiary);
  border-radius: 8px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

/* Required Input Field */
.required-input::after {
  content: " *";
  color: #c0272d;
}

/* To remove scrollbar in number input field */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  appearance: none;
}

/* disable Item */
.item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  border-radius: 3px;
  background-color: #f2f2f2;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.9rem;
  height: 0.9rem;
  clip-path: polygon(
    20% 55%,
    35% 70%,
    50% 85%,
    85% 35%,
    75% 25%,
    50% 65%,
    30% 45%
  );
  transform: scale(0);
  background-color: black;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:hover {
  color: black;
}
